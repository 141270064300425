import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { getIdentifiersFromStorage } from 'utils/identifiers'
import { rudderanalytics } from 'utils/rudderstack'

export const EventTracking = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    const identifiers = getIdentifiersFromStorage()
    rudderanalytics.track('full_page_view', {
      ...identifiers,
    })
  }, [pathname])
  return null
}
