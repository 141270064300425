import React, { useEffect, useMemo, useState } from 'react'
import Typography from '@luce/ui-kit/components/base/Typography'
import Images from 'assets/images/Images'
import BoxRow from '@luce/ui-kit/components/atom/BoxRow'
import {
  landscapeMobileScreen,
  useMediaQuery,
} from '../../booking/ui-component/MediaQueryResponsive'
import Box from '@luce/ui-kit/components/base/Box'
import { getTokenDecode } from 'services/apollo'
import useAuth from 'hooks/AuthHook'
import {
  getIdentifiersFromStorage,
  getNewAppLinkWithIdentifiers,
} from 'utils/identifiers'
import { useLocation } from 'react-router-dom'
import { rudderanalytics } from 'utils/rudderstack'
import { capture as posthogCapture, getPosthogIdentifiers } from 'utils/posthog'
import { triggerEvent } from 'services/gtm'
export const CLIENT_SWITCH_TO_NEW_APP_KEY = 'using_new_app'

const newLuceAppLink = import.meta.env.VITE_REACT_NEW_LUCE_APP_LINK

const isClientUsingNewApp = () =>
  localStorage.getItem(CLIENT_SWITCH_TO_NEW_APP_KEY) === '1'

export const useRedirectNewApp = (loggedIn: boolean) => {
  const [isUsingNewApp, setUsingNewApp] = useState<boolean | null>(null)

  useEffect(() => {
    setUsingNewApp(isClientUsingNewApp())
    const onStorage = (event: StorageEvent) => {
      if (event.key === CLIENT_SWITCH_TO_NEW_APP_KEY) {
        setUsingNewApp(isClientUsingNewApp())
      }
    }
    window.addEventListener('storage', onStorage)
    return () => {
      window.removeEventListener('storage', onStorage)
    }
  }, [])

  useEffect(() => {
    let redirectTimeout: NodeJS.Timeout
    if (loggedIn && isClientUsingNewApp()) {
      redirectTimeout = setTimeout(() => {
        const identifiers = getIdentifiersFromStorage()
        rudderanalytics.track('redirect_to_new_app', {
          is_auto_redirect: true,
          ...identifiers,
        })
        triggerEvent('redirect_to_new_app', {})
        posthogCapture('redirect_to_new_app', {
          is_auto_redirect: true,
        })
        location.href = getNewAppLinkWithIdentifiers()
      }, 1000)
    }

    return () => {
      clearTimeout(redirectTimeout)
    }
  }, [isUsingNewApp, loggedIn])
}

const SwitchBanner: React.FC = () => {
  const landscape = useMediaQuery(landscapeMobileScreen)
  const clientId = useAuth().user?.id ?? ''
  const tokenData = getTokenDecode(clientId)

  const { pathname } = useLocation()

  const onOpenNewLuce = () => {
    try {
      const link = new URL(newLuceAppLink)
      const identifiers = getIdentifiersFromStorage()
      const { distinct_id, session_id } = getPosthogIdentifiers()
      const params = {
        ...identifiers,
        ...tokenData,
        distinct_id,
        session_id,
      }
      Object.entries(params).forEach(([key, values]) => {
        if (values) {
          link.searchParams.set(key, values)
        }
      })
      rudderanalytics.track('redirect_to_new_app', {
        ...identifiers,
      })
      triggerEvent('redirect_to_new_app', {})
      posthogCapture('redirect_to_new_app')
      window.location.href = link.toString()
    } catch (error) {
      const queries = new URLSearchParams(tokenData)
      window.location.href = `${newLuceAppLink}?${queries.toString()}`
      console.info(
        'create URL failed, new luce app link:',
        newLuceAppLink,
        error
      )
    } finally {
      localStorage.setItem(CLIENT_SWITCH_TO_NEW_APP_KEY, '1')
    }
  }

  const shouldShowBanner = useMemo(() => {
    const pages = ['home', 'upcoming', 'history']
    const firstPath = pathname.split('/')[1] ?? ''
    return pages.some((page) => firstPath.includes(page))
  }, [pathname])

  if (
    !shouldShowBanner ||
    !newLuceAppLink ||
    import.meta.env.VITE_REACT_SHOW_SWITCH !== '1'
  ) {
    return null
  }

  return (
    <BoxRow
      alignItems="flex-start"
      bgcolor="#F8FAFB"
      gap={2}
      py={2}
      px={3}
      position="sticky"
      id="switch-banner"
    >
      <Box mt={landscape ? 1 : 0}>
        <img src={Images.LUCE_ICON} width={16} alt="luce" />
      </Box>
      <BoxRow flex={1} flexDirection="row" gap={2}>
        <Typography color="primary" variant="overline">
          Discover the New Luce! Our app now features a fresh look and enhanced
          style. Switch to the new version anytime.
          <Typography
            variant="overline"
            color="secondary"
            onClick={onOpenNewLuce}
            style={{ cursor: 'pointer', fontWeight: 500 }}
          >
            Try it now!
          </Typography>
        </Typography>
      </BoxRow>
    </BoxRow>
  )
}

export default SwitchBanner
