import posthog, { PostHog } from 'posthog-js'
import { isLocal } from './helpers'

export let posthogInstance: PostHog

const POSTHOG_DISTINCT_ID_KEY = 'cross_posthog_distinct_id'
const POSTHOG_SESSION_ID_KEY = 'cross_posthog_session_id'

const validateQueryValue = (value: string | null) => {
  if (!value || value === 'undefined') {
    return undefined
  }

  return value
}

export const init = () => {
  if (isLocal()) {
    console.log('[PostHog] Development mode – analytics disabled')
    return
  }

  const queryParams = new URLSearchParams(window.location.search)
  const distinct_id = validateQueryValue(queryParams.get('distinct_id'))
  const session_id = validateQueryValue(queryParams.get('session_id'))

  localStorage.setItem(POSTHOG_DISTINCT_ID_KEY, distinct_id ?? '')
  localStorage.setItem(POSTHOG_SESSION_ID_KEY, session_id ?? '')

  posthogInstance = posthog.init(import.meta.env.VITE_REACT_POSTHOG_KEY, {
    api_host: 'https://app.posthog.com',
    autocapture: true,
    bootstrap: {
      sessionID: session_id,
      distinctID: distinct_id,
      featureFlags: {
        home_cleaning_navigation: 'control',
      },
    },
  })
}

export const identify = (
  userId: string,
  properties?: Record<string, unknown>
) => {
  if (isLocal()) return
  posthogInstance.identify(userId, properties)
  posthogInstance.reloadFeatureFlags()
}

export const capture = (
  event: string,
  properties?: Record<string, unknown>
) => {
  if (isLocal()) return
  posthogInstance.capture(event, properties)
}

export const reset = () => {
  if (isLocal()) return
  posthogInstance.reset()
}

export const getPosthogIdentifiers = () => {
  const session_id = posthogInstance?.get_session_id()
  const distinct_id = posthogInstance?.get_distinct_id()

  return { distinct_id, session_id }
}
