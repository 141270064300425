const UTM_KEY = 'UTM'
const GOOGLE_CLICK_ID = 'GCLID'
const FACEBOOK_CLICK_ID = 'FBCLID'
const MICROSOFT_CLICK_ID = 'MSCLKID'
const TIKTOK_CLICK_ID = 'TTCLID'
const LINKEDIN_CLICK_ID = 'LI_FAT_ID'

export const SESSION_MINUTES = 30
export const SEVEN_DAYS_MINUTES = 7 * 24 * 60

interface UTM {
  utm_source?: string
  utm_medium?: string
  utm_campaign?: string
  utm_term?: string
  utm_content?: string
}

export const setItemWithExpiry = (
  key: string,
  value: unknown,
  expiryInMinutes: number
) => {
  const now = new Date()
  const item = {
    value,
    expiry: now.getTime() + expiryInMinutes * 60000,
  }
  localStorage.setItem(key, JSON.stringify(item))
}

const getItemWithExpiry = <T = unknown>(key: string): T => {
  const itemStr = localStorage.getItem(key)
  if (!itemStr) return null as T

  const item = JSON.parse(itemStr) as { value: unknown; expiry: number }
  const now = new Date()

  if (!item.expiry || now.getTime() > item.expiry) {
    localStorage.removeItem(key)
    return null as T
  }
  return item.value as T
}

const getIdentifiersParameters = () => {
  const urlParams = new URLSearchParams(window.location.search)
  return {
    utms: {
      utm_source: urlParams.get('utm_source'),
      utm_medium: urlParams.get('utm_medium'),
      utm_campaign: urlParams.get('utm_campaign'),
      utm_term: urlParams.get('utm_term'),
      utm_content: urlParams.get('utm_content'),
    },
    gclid: urlParams.get('gclid'),
    fbclid: urlParams.get('fbclid'),
    msclkid: urlParams.get('msclkid'),
    ttclid: urlParams.get('ttclid'),
    li_fat_id: urlParams.get('li_fat_id'),
  }
}

export const storeIdentifiers = () => {
  const { utms, gclid, fbclid, msclkid, ttclid, li_fat_id } =
    getIdentifiersParameters()

  if (gclid) setItemWithExpiry(GOOGLE_CLICK_ID, gclid, SEVEN_DAYS_MINUTES)
  if (fbclid) setItemWithExpiry(FACEBOOK_CLICK_ID, fbclid, SEVEN_DAYS_MINUTES)
  if (msclkid)
    setItemWithExpiry(MICROSOFT_CLICK_ID, msclkid, SEVEN_DAYS_MINUTES)
  if (ttclid) setItemWithExpiry(TIKTOK_CLICK_ID, ttclid, SEVEN_DAYS_MINUTES)
  if (li_fat_id)
    setItemWithExpiry(LINKEDIN_CLICK_ID, li_fat_id, SEVEN_DAYS_MINUTES)

  if (utms.utm_source) {
    setItemWithExpiry(UTM_KEY, utms, SESSION_MINUTES)
  }
}

export const getIdentifiersFromStorage = () => {
  const gclid = getItemWithExpiry<string | undefined>(GOOGLE_CLICK_ID)
  const fbclid = getItemWithExpiry<string | undefined>(FACEBOOK_CLICK_ID)
  const msclkid = getItemWithExpiry<string | undefined>(MICROSOFT_CLICK_ID)
  const ttclid = getItemWithExpiry<string | undefined>(TIKTOK_CLICK_ID)
  const li_fat_id = getItemWithExpiry<string | undefined>(LINKEDIN_CLICK_ID)
  const utms = getItemWithExpiry<UTM | null>(UTM_KEY)

  return {
    gclid: gclid || '',
    fbclid: fbclid || '',
    msclkid: msclkid || '',
    ttclid: ttclid || '',
    li_fat_id: li_fat_id || '',
    utm_source: utms?.utm_source || '',
    utm_medium: utms?.utm_medium || '',
    utm_campaign: utms?.utm_campaign || '',
    utm_term: utms?.utm_term || '',
    utm_content: utms?.utm_content || '',
  }
}

export const getNewAppLinkWithIdentifiers = () => {
  const newLuceAppLink = import.meta.env.VITE_REACT_NEW_LUCE_APP_LINK

  try {
    const link = new URL(newLuceAppLink)
    const identifiers = getIdentifiersFromStorage()
    Object.entries(identifiers).forEach(([key, values]) => {
      if (values) {
        link.searchParams.set(key, values)
      }
    })

    return link.toString()
  } catch (error) {
    console.error('Failed to generate new app link with identifiers:', error)
    return newLuceAppLink
  }
}
